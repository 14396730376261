iframe::-webkit-media-controls-enclosure {
  display: none !important;
}
embed::-webkit-media-controls-enclosure {
  display: none !important;
}

.embedded-content-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embedded-content-container embed::-webkit-media-controls-panel {
  display: none !important;
}
